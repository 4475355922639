<template>
  <div>
    <div
      v-if="!allowLicense"
      style="height: calc(100vh - 145px)"
      class="w-100 text-center d-flex align-center justify-center"
    >
      <div class="error--text text-h6">
        {{ $t("labels.you_have_not_register_finance_service") }} <br />
        {{ $t("labels.contact_to_customer_care") }}
      </div>
    </div>
    <div v-else>
      <div v-if="viewOption === 'chart'" style="height: calc(100vh - 145px)">
        <div>
          <div class="d-flex align-center">
            <div class="mr-2 font-weight-medium chart-filter-title">
              {{ $t("labels.data") }}:
            </div>
            <v-radio-group
              v-model="filters.group_time"
              row
              dense
              hide-details
              class="mt-0 pt-0 chart-filter-content"
            >
              <v-radio
                v-for="(item, key) in groupTimeOptions"
                :key="`t_${item.value}`"
                :label="item.text"
                :value="item.value"
                @change="changeGroupTime"
                :class="`option-col-${key}`"
                class="c-input-small"
              ></v-radio>
            </v-radio-group>
          </div>

          <div class="d-flex align-center">
            <div class="mr-2 font-weight-medium chart-filter-title">
              {{ $t("labels.from_to") }}:
            </div>
            <div class="chart-filter-content">
              <template v-if="filters.group_time === 'date'">
                <div class="mr-5 chart-filter-from-to">
                  <date-picker-model
                    v-model="filters.date_from"
                    :placeholder="$t('labels.from')"
                    custom-class="c-input-small input-disabled-bold"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></date-picker-model>
                </div>
                <div class="mr-5 chart-filter-from-to">
                  <date-picker-model
                    v-model="filters.date_to"
                    :placeholder="$t('labels.to')"
                    custom-class="c-input-small input-disabled-bold"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></date-picker-model>
                </div>
              </template>
              <template v-else-if="filters.group_time === 'month'">
                <div class="mr-5 chart-filter-from-to">
                  <month-picker-model
                    v-model="filters.date_from"
                    :placeholder="$t('labels.from')"
                    custom-class="c-input-small input-disabled-bold"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></month-picker-model>
                </div>
                <div class="mr-5 chart-filter-from-to">
                  <month-picker-model
                    v-model="filters.date_to"
                    :placeholder="$t('labels.to')"
                    custom-class="c-input-small input-disabled-bold"
                    outlined
                    dense
                    hide-details
                    single-line
                  ></month-picker-model>
                </div>
              </template>
              <template v-else-if="filters.group_time === 'quarter'">
                <div class="mr-5 chart-filter-from-to">
                  <v-autocomplete
                    class="c-input-xs"
                    v-model="filters.date_from"
                    :items="quarterOptions"
                    :placeholder="$t('labels.from')"
                    dense
                    outlined
                    hide-details
                  ></v-autocomplete>
                </div>
                <div class="mr-5 chart-filter-from-to">
                  <v-autocomplete
                    class="c-input-xs"
                    v-model="filters.date_to"
                    :items="quarterOptions"
                    :placeholder="$t('labels.to')"
                    dense
                    outlined
                    hide-details
                  ></v-autocomplete>
                </div>
              </template>
              <template v-else-if="filters.group_time === 'year'">
                <div class="mr-5 chart-filter-from-to">
                  <v-autocomplete
                    class="c-input-xs"
                    v-model="filters.date_from"
                    :items="yearOptions"
                    :placeholder="$t('labels.from')"
                    dense
                    outlined
                    hide-details
                  ></v-autocomplete>
                </div>
                <div class="mr-5 chart-filter-from-to">
                  <v-autocomplete
                    class="c-input-xs"
                    v-model="filters.date_to"
                    :items="yearOptions"
                    :placeholder="$t('labels.to')"
                    dense
                    outlined
                    hide-details
                  ></v-autocomplete>
                </div>
              </template>
            </div>
          </div>

          <div class="d-flex align-center">
            <div class="mr-2 font-weight-medium chart-filter-title">
              {{ $t("labels.chart") }}:
            </div>
            <v-radio-group
              v-model="filters.group_source"
              row
              dense
              hide-details
              class="mt-0 pt-0 chart-filter-content"
            >
              <v-radio
                v-for="(item, key) in groupSourceOptions"
                :key="`t_${item.value}`"
                :label="item.text"
                :value="item.value"
                :class="`option-col-${key}`"
                class="c-input-small"
              ></v-radio>
            </v-radio-group>
          </div>

          <div class="d-flex align-center">
            <div class="mr-2 font-weight-medium chart-filter-title">
              {{ $t("labels.source") }}:
            </div>
            <div class="chart-filter-content">
              <div
                v-for="(item, key) in sourceOptions"
                :key="`t_${item.value}`"
                class="mr-5"
                :class="`option-col-${key}`"
              >
                <v-checkbox
                  v-model="filters.sources"
                  :label="item.text"
                  class="mt-0 pt-0 c-input-small"
                  :value="item.value"
                  dense
                  hide-details
                ></v-checkbox>
              </div>
            </div>
          </div>

          <div class="d-flex align-center">
            <div class="mr-2 font-weight-medium chart-filter-title">
              {{ $t("labels.display") }}:
            </div>
            <div class="chart-filter-content">
              <div
                v-for="(item, key) in displayOptions"
                :key="`t_${item.value}`"
                class="mr-5"
                :class="`option-col-${key}`"
              >
                <v-checkbox
                  v-model="chartColumns"
                  :label="item.text"
                  class="mt-0 pt-0 c-input-small"
                  :value="item.value"
                  dense
                  hide-details
                ></v-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div class="chart-section">
          <GChart
            v-if="chartData.length > 0"
            style="height: 500px"
            type="LineChart"
            :data="chartData"
            :options="chartOptions"
          />

          <div class="chart-title">
            {{ $t("labels.summary_business_result") }}
          </div>

          <div
            class="bottom-line-desc"
            v-if="filters.group_source === 'separation' && chartData.length > 0"
          >
            <div
              class="bottom-line-item source-1"
              v-if="filters.sources.length === 0 || filters.sources.includes(1)"
            >
              <span></span> {{ $t("labels.administration_source_1") }}
            </div>
            <div
              class="bottom-line-item source-2"
              v-if="filters.sources.length === 0 || filters.sources.includes(2)"
            >
              <span></span> {{ $t("labels.administration_source_2") }}
            </div>
            <div
              class="bottom-line-item source-3"
              v-if="filters.sources.length === 0 || filters.sources.includes(3)"
            >
              <span></span> {{ $t("labels.administration_source_3") }}
            </div>
            <div
              class="bottom-line-item source-4"
              v-if="filters.sources.length === 0 || filters.sources.includes(4)"
            >
              <span></span> {{ $t("labels.administration_source_4") }}
            </div>
            <div
              class="bottom-line-item source-0"
              v-if="filters.sources.length === 0 || filters.sources.includes(0)"
            >
              <span></span> {{ $t("labels.administration_source_0") }}
            </div>
          </div>

          <div class="right-line-desc">
            <div
              class="right-line-item line-revenue"
              v-if="chartColumns.includes('revenue')"
            >
              <span class="line-width" v-for="i in 1" :key="i"></span>
              {{ $t("labels.revenue") }}
              <small class="error--text font-weight-medium">{{
                abbreviateNumber(sum.sum_revenue)
              }}</small>
            </div>
            <div
              class="right-line-item line-revenue-deduction"
              v-if="chartColumns.includes('revenue_deduction')"
            >
              <span class="line-width" v-for="i in 3" :key="i"></span>
              {{ $t("labels.revenue_deduction") }}
              <small class="error--text font-weight-medium">{{
                abbreviateNumber(sum.sum_revenue_deduction)
              }}</small>
            </div>
            <div
              class="right-line-item line-revenue-net"
              v-if="chartColumns.includes('revenue_net')"
            >
              <span class="line-width" v-for="i in 4" :key="i"></span>
              {{ $t("labels.revenue_net") }}
              <small class="error--text font-weight-medium">{{
                abbreviateNumber(sum.sum_revenue_net)
              }}</small>
            </div>
            <div
              class="right-line-item line-total-cost"
              v-if="chartColumns.includes('total_cost')"
            >
              <span class="line-width" v-for="i in 15" :key="i"></span>
              {{ $t("labels.total_cost") }}
              <small class="error--text font-weight-medium">{{
                abbreviateNumber(sum.sum_total_cost)
              }}</small>
            </div>
            <div
              class="right-line-item line-gross-profit"
              v-if="chartColumns.includes('gross_profit')"
            >
              <span class="line-width" v-for="i in 6" :key="i"></span>
              {{ $t("labels.gross_profit") }}
              <small class="error--text font-weight-medium">
                {{ abbreviateNumber(sum.sum_gross_profit) }}
              </small>
            </div>
          </div>
        </div>
      </div>

      <v-simple-table
        v-if="viewOption === 'table'"
        fixed-header
        height="calc(100vh - 145px)"
        class="table-padding-2"
      >
        <template v-slot:default>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-center">
                <DateRangeFilter
                  :label="$t('labels.date')"
                  :placeholder="$t('labels.date')"
                  name="date"
                  sort-name="date"
                  :sorting="filters.sort_by"
                  :default-value="filters.date"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="width: 120px">
                <SelectFilter
                  :options="sourceOptions"
                  :label="$t('labels.source')"
                  :placeholder="$t('labels.source')"
                  name="source"
                  sort-name="source"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="width: 120px">
                <SelectEMarketShop
                  class="c-input-xs input-filter"
                  :label="$t('labels.shop')"
                  :placeholder="$t('labels.shop')"
                  name="id_e_market_shop"
                  sort-name="shop_name"
                  :filters="filters"
                  :is-check-e-market="false"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <th role="columnheader" style="width: 120px">
                <SelectWarehouse
                  class="c-input-xs input-filter"
                  :label="$t('labels.warehouse_1')"
                  :placeholder="$t('labels.warehouse_1')"
                  name="id_warehouse"
                  sort-name="warehouse_code"
                  :sorting="filters.sort_by"
                  @onFilter="onFilterChange"
                  @onSort="onSortChange"
                />
              </th>
              <template
                v-if="checkPermission(['administration_report_revenue'])"
              >
                <th role="columnheader">
                  <InputFilterFromTo
                    :label="$t('labels.revenue')"
                    :placeholder="$t('labels.revenue')"
                    name="revenue"
                    sort-name="revenue"
                    :sorting="filters.sort_by"
                    @onFilter="onFilterChange"
                    @onSort="onSortChange"
                  />
                </th>
                <th role="columnheader">
                  <InputFilterFromTo
                    :label="$t('labels.revenue_deduction')"
                    :placeholder="$t('labels.revenue_deduction')"
                    name="revenue_deduction"
                    sort-name="revenue_deduction"
                    :sorting="filters.sort_by"
                    @onFilter="onFilterChange"
                    @onSort="onSortChange"
                  />
                </th>
                <th role="columnheader">
                  <InputFilterFromTo
                    :label="$t('labels.revenue_net')"
                    :placeholder="$t('labels.revenue_net')"
                    name="revenue_net"
                    sort-name="revenue_net"
                    :sorting="filters.sort_by"
                    @onFilter="onFilterChange"
                    @onSort="onSortChange"
                  />
                </th>
              </template>
              <template
                v-if="checkPermission(['administration_report_cost_profit'])"
              >
                <th role="columnheader">
                  <InputFilterFromTo
                    :label="$t('labels.total_cost')"
                    :placeholder="$t('labels.total_cost')"
                    name="total_cost"
                    sort-name="total_cost"
                    :sorting="filters.sort_by"
                    @onFilter="onFilterChange"
                    @onSort="onSortChange"
                  />
                </th>
                <th role="columnheader">
                  <InputFilterFromTo
                    :label="$t('labels.gross_profit')"
                    :placeholder="$t('labels.gross_profit')"
                    name="gross_profit"
                    sort-name="gross_profit"
                    :sorting="filters.sort_by"
                    @onFilter="onFilterChange"
                    @onSort="onSortChange"
                  />
                </th>
              </template>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in items"
              :key="`fr_${key}`"
              class="text-center"
            >
              <td>{{ formatDateTime(item.date, "DD/MM/YYYY") }}</td>
              <td>{{ item.source_text }}</td>
              <td>{{ item.shop_name }}</td>
              <td>{{ item.warehouse_code }}</td>
              <!-- <td>{{ item.pos_code }}</td> -->
              <template
                v-if="checkPermission(['administration_report_revenue'])"
              >
                <td>{{ formatNumber(item.revenue) }}</td>
                <td>{{ formatNumber(item.revenue_deduction) }}</td>
                <td>{{ formatNumber(item.revenue_net) }}</td>
              </template>
              <template
                v-if="checkPermission(['administration_report_cost_profit'])"
              >
                <td>{{ formatNumber(item.total_cost) }}</td>
                <td>{{ formatNumber(item.gross_profit) }}</td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-row class="pt-3">
        <v-col cols="12" md="4">
          <div class="d-flex align-center">
            <div>
              <template
                v-if="checkPermission(['administration_report_revenue'])"
              >
                <div class="d-flex table-footer-count">
                  <div class="mr-2">
                    {{ $t("labels.revenue") }}:
                    <b>{{ formatNumber(sum.sum_revenue) }}</b>
                  </div>
                  <div class="mr-2">
                    {{ $t("labels.revenue_deduction") }}:
                    <b>{{ formatNumber(sum.sum_revenue_deduction) }}</b>
                  </div>
                </div>
              </template>
              <div class="d-flex table-footer-count">
                <template
                  v-if="checkPermission(['administration_report_revenue'])"
                >
                  <div class="mr-2">
                    {{ $t("labels.revenue_net_1") }}:
                    <b>{{ formatNumber(sum.sum_revenue_net) }}</b>
                  </div>
                </template>

                <template
                  v-if="checkPermission(['administration_report_cost_profit'])"
                >
                  <div class="mr-2">
                    {{ $t("labels.total_cost_1") }}:
                    <b>{{ formatNumber(sum.sum_total_cost) }}</b>
                  </div>
                  <div class="mr-2">
                    {{ $t("labels.gross_profit_1") }}:
                    <b>{{ formatNumber(sum.sum_gross_profit) }}</b>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <v-pagination
            v-if="viewOption === 'table'"
            v-model="page"
            :length="totalPage"
            :total-visible="4"
          ></v-pagination>
        </v-col>
        <v-col cols="12" md="4" class="text-right">
          <v-btn color="success" class="mr-2" @click="downloadExcel">
            <v-icon>mdi-download</v-icon>
            {{ $t("labels.excel") }}
          </v-btn>
          <v-btn
            color="secondary"
            @click="setViewOption('table')"
            v-if="['chart'].includes(viewOption)"
          >
            <v-icon>mdi-table</v-icon>
            {{ $t("labels.table") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="setViewOption('chart')"
            v-if="['table'].includes(viewOption)"
          >
            <v-icon>mdi-chart-line</v-icon>
            {{ $t("labels.chart") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";
import { GChart } from "vue-google-charts/legacy";
import moment from "moment/moment";
const DEFAULT_DATES = [
  moment().subtract("30", "days").format("YYYY-MM-DD"),
  moment().format("YYYY-MM-DD"),
];

export default {
  name: "ReportList",
  components: {
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    // SelectPos: () => import('@/components/table/SelectPos'),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    SelectEMarketShop: () => import("@/components/common/SelectEMarketShop"),
    GChart,
  },
  data: () => ({
    isLoading: false,
    items: [],
    sum: {},
    page: 1,
    total: 0,
    totalPage: 1,
    viewOption: "chart",
    chartColumns: [],
    filters: {},
  }),
  computed: {
    allowLicense() {
      return this.checkPermission(["administration_report_revenue"]);
    },
    chartOptions() {
      const options = {
        chart: {
          title: "Kết quả hoạt động kinh doanh",
        },
        legend: { position: "none" },
        interpolateNulls: true,
      };

      let series = {};
      const sources =
        this.filters.sources && this.filters.sources.length > 0
          ? this.filters.sources
          : [1, 2, 3, 4, 0];

      if (this.filters.group_source === "separation") {
        const columnSources = [];
        this.chartColumns.forEach((d) => {
          sources.forEach((s) => {
            columnSources.push(`${d}@${s}`);
          });
        });

        columnSources.forEach((d, k) => {
          const [col, sour] = d.split("@");
          switch (col) {
            case "revenue":
              series[k] = { lineDashStyle: [0, 0] };
              break;
            case "revenue_deduction":
              series[k] = { lineDashStyle: [10, 2] };
              break;
            case "revenue_net":
              series[k] = { lineDashStyle: [4, 4] };
              break;
            case "total_cost":
              series[k] = { lineDashStyle: [1, 1] };
              break;
            case "gross_profit":
              series[k] = { lineDashStyle: [4, 1] };
              break;
          }
          switch (sour) {
            case "1":
              series[k].color = "#e2431e";
              break;
            case "2":
              series[k].color = "#e7711b";
              break;
            case "3":
              series[k].color = "#f1ca3a";
              break;
            case "4":
              series[k].color = "#6f9654";
              break;
            case "0":
              series[k].color = "#1c91c0";
              break;
          }
        });
      } else {
        series = {};
        this.chartColumns.forEach((d, k) => {
          switch (d) {
            case "revenue":
              series[k] = { lineDashStyle: [0, 0], color: "#e2431e" };
              break;
            case "revenue_deduction":
              series[k] = { lineDashStyle: [10, 2], color: "#e7711b" };
              break;
            case "revenue_net":
              series[k] = { lineDashStyle: [4, 4], color: "#f1ca3a" };
              break;
            case "total_cost":
              series[k] = { lineDashStyle: [1, 1], color: "#6f9654" };
              break;
            case "gross_profit":
              series[k] = { lineDashStyle: [4, 1], color: "#1c91c0" };
              break;
          }
        });
      }

      return { ...options, series };
    },
    chartData() {
      if (!this.items || this.items.length === 0) {
        return false;
      }
      if (this.filters.group_source === "separation") {
        return this.chartDataSeparation;
      } else {
        return this.chartDataSummary;
      }
    },
    chartDataSeparation() {
      const items = [];
      const titles = ["Thời gian"];
      const columnSources = ["time"];
      const sources =
        this.filters.sources && this.filters.sources.length > 0
          ? this.filters.sources
          : [1, 2, 3, 4, 0];

      this.chartColumns.forEach((d) => {
        sources.forEach((s) => {
          const label = this.$t(`labels.${d}`);
          const source = this.$t(`labels.administration_source_${s}`);
          titles.push(`${label} ${source}`);
          columnSources.push(`${d}@${s}`);
        });
      });

      items.push(titles);

      const timeSourceValues = {};

      this.items.forEach((item) => {
        let time = null;
        if (this.filters.group_time === "year") {
          time = `${item.year}`;
        } else if (this.filters.group_time === "quarter") {
          time = `Q${item.quarter} / ${item.year}`;
        } else if (this.filters.group_time === "month") {
          time = `${item.month}/${item.year}`;
        } else {
          time = this.formatDateTime(item.date, "DD/MM/YYYY");
        }

        if (!timeSourceValues[time]) {
          timeSourceValues[time] = {};
        }

        if (!timeSourceValues[time][item.source]) {
          timeSourceValues[time][item.source] = {};
        }

        timeSourceValues[time][item.source] = {
          sum_revenue: item.sum_revenue,
          sum_revenue_deduction: item.sum_revenue_deduction,
          sum_revenue_net: item.sum_revenue_net,
          sum_total_cost: item.sum_total_cost,
          sum_gross_profit: item.sum_gross_profit,
        };
      });

      const times = Object.keys(timeSourceValues);
      times.forEach((time) => {
        const values = [time];
        columnSources.forEach((cs) => {
          const [col, sour] = cs.split("@");
          if (col !== "time") {
            const value =
              (timeSourceValues[time] &&
                timeSourceValues[time][sour] &&
                timeSourceValues[time][sour][`sum_${col}`]) ||
              0;
            values.push(+value);
          }
        });
        items.push(values);
      });
      return items;
    },
    chartDataSummary() {
      const items = [];
      const titles = ["Thời gian"];
      this.chartColumns.forEach((d) => {
        titles.push(this.$t(`labels.${d}`));
      });
      items.push(titles);
      this.items.forEach((item) => {
        const values = [];

        if (this.filters.group_time === "year") {
          values.push(`${item.year}`);
        } else if (this.filters.group_time === "quarter") {
          values.push(`Q${item.quarter} / ${item.year}`);
        } else if (this.filters.group_time === "month") {
          values.push(`${item.month}/${item.year}`);
        } else {
          values.push(this.formatDateTime(item.date, "DD/MM/YYYY"));
        }

        this.chartColumns.forEach((d) => {
          values.push(+item[`sum_${d}`]);
        });
        items.push(values);
      });
      console.log("items", items);
      return items;
    },
    groupTimeOptions() {
      return [
        {
          text: this.$t("labels.date_1"),
          value: "date",
        },
        {
          text: this.$t("labels.month_1"),
          value: "month",
        },
        {
          text: this.$t("labels.quarter"),
          value: "quarter",
        },
        {
          text: this.$t("labels.year_1"),
          value: "year",
        },
      ];
    },
    groupSourceOptions() {
      return [
        {
          text: this.$t("labels.summary"),
          value: "summary",
        },
        {
          text: this.$t("labels.separation"),
          value: "separation",
        },
      ];
    },
    sourceOptions() {
      return [
        {
          text: this.$t("labels.administration_source_1"),
          value: 1,
        },
        {
          text: this.$t("labels.administration_source_2"),
          value: 2,
        },
        {
          text: this.$t("labels.administration_source_3"),
          value: 3,
        },
        {
          text: this.$t("labels.administration_source_4"),
          value: 4,
        },
        {
          text: this.$t("labels.administration_source_0"),
          value: 0,
        },
      ];
    },
    displayOptions() {
      if (
        this.checkPermission(["administration_report_revenue"]) &&
        this.checkPermission(["administration_report_cost_profit"])
      ) {
        return [
          {
            text: this.$t("labels.revenue"),
            value: "revenue",
          },
          {
            text: this.$t("labels.revenue_deduction"),
            value: "revenue_deduction",
          },
          {
            text: this.$t("labels.revenue_net"),
            value: "revenue_net",
          },
          {
            text: this.$t("labels.total_cost"),
            value: "total_cost",
          },
          {
            text: this.$t("labels.gross_profit"),
            value: "gross_profit",
          },
        ];
      } else if (this.checkPermission(["administration_report_cost_profit"])) {
        return [
          {
            text: this.$t("labels.total_cost"),
            value: "total_cost",
          },
          {
            text: this.$t("labels.gross_profit"),
            value: "gross_profit",
          },
        ];
      } else if (this.checkPermission(["administration_report_revenue"])) {
        return [
          {
            text: this.$t("labels.revenue"),
            value: "revenue",
          },
          {
            text: this.$t("labels.revenue_deduction"),
            value: "revenue_deduction",
          },
          {
            text: this.$t("labels.revenue_net"),
            value: "revenue_net",
          },
        ];
      } else {
        return [];
      }
    },
    quarterOptions() {
      const quarters = [];
      const currentYear = moment().year();
      for (let i = 2021; i <= currentYear; i++) {
        for (let j = 1; j <= 4; j++) {
          quarters.push({
            text: `Q${j} / ${i}`,
            value: `${i}-${j}`,
          });
        }
      }
      return quarters;
    },
    yearOptions() {
      const years = [];
      const currentYear = moment().year();
      for (let i = 2021; i <= currentYear; i++) {
        years.push({
          text: i,
          value: i,
        });
      }
      return years;
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.items = [];
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.allowLicense) {
      return false;
    }
    if (
      this.checkPermission(["administration_report_revenue"]) &&
      this.checkPermission(["administration_report_cost_profit"])
    ) {
      this.chartColumns = ["revenue_net", "total_cost", "gross_profit"];
    } else if (this.checkPermission(["administration_report_cost_profit"])) {
      this.chartColumns = ["total_cost", "gross_profit"];
    } else if (this.checkPermission(["administration_report_cost_profit"])) {
      this.chartColumns = ["revenue", "revenue_deduction", "revenue_net"];
    } else {
      this.chartColumns = [];
    }
    this.setViewOption(this.viewOption);
    this.getList();
  },
  methods: {
    changeGroupTime() {
      this.filters = { ...this.filters, date_from: null, date_to: null };
    },
    setViewOption(mode) {
      this.viewOption = mode;
      let filters = {
        date: [],
        sources: [1, 2, 3, 4, 0],
        date_from: null,
        date_to: null,
        group_time: null,
        group_source: null,
      };
      if (this.viewOption === "chart") {
        filters.group_time = "date";
        filters.group_source = "summary";
      } else {
        filters.date = [...DEFAULT_DATES];
      }
      this.filters = { ...filters, viewMode: this.viewOption };
    },
    getSourceText(item) {
      return this.sourceOptions.find((p) => p.value === item.source) || {};
    },
    getList: debounce(function () {
      httpClient
        .post("/finance-report-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.total = data.total;
          this.sum = { ...data.sum };
          this.items = [...data.rows].map((r) => {
            const source = this.getSourceText(r);
            r.source_text = source.text || "";
            return r;
          });
        });
    }, 1000),

    async downloadExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      const filename = "tai-chinh.xlsx";

      try {
        await this.downloadExcelFile(
          "/finance-report-export",
          { ...this.filters },
          filename
        );
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
      }
    },

    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
  },
};
</script>

<style scoped lang="scss">
.chart-filter-title {
  height: 40px;
  line-height: 40px;
  width: 75px;
  font-size: 14px;
}

.chart-filter-content {
  width: calc(100% - 83px);
  display: flex;
  align-items: center;
}

.option-col-0 {
  width: 10%;
}

.option-col-1 {
  width: 15%;

  &.v-radio {
    margin-left: 4px;
  }
}

.option-col-2 {
  width: 14%;

  &.v-radio {
    margin-left: 4px;
  }
}

.option-col-3 {
  width: 14%;

  &.v-radio {
    margin-left: 4px;
  }
}

.option-col-4 {
  width: 15%;
}

.chart-filter-from-to {
  width: 25%;
}

.chart-section {
  position: relative;
}

.chart-title {
  position: absolute;
  top: 20px;
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 500;
  color: #666;
}

.bottom-line-desc {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 30px;
  width: 100%;
  font-size: 14px;

  .bottom-line-item {
    margin-left: 50px;
    position: relative;

    &:first-child {
      margin-left: 0;
    }

    &.source-1 {
      span {
        background-color: #e2431e;
      }
    }

    &.source-2 {
      span {
        background-color: #e7711b;
      }
    }

    &.source-3 {
      span {
        background-color: #f1ca3a;
      }
    }

    &.source-4 {
      span {
        background-color: #6f9654;
      }
    }

    &.source-0 {
      span {
        background-color: #1c91c0;
      }
    }

    span {
      position: absolute;
      content: "";
      height: 2px;
      width: 25px;
      left: -30px;
      top: 10px;
    }
  }
}

.right-line-desc {
  position: absolute;
  right: 2px;
  bottom: 200px;
  font-size: 14px;

  .right-line-item {
    position: relative;

    .line-width {
      position: absolute;
      content: "";
      height: 2px;
      top: 10px;
    }

    &.line-revenue {
      .line-width {
        background-color: #666;
        width: 30px;
        left: -35px;
      }
    }

    &.line-revenue-deduction {
      .line-width {
        background-color: #666;
        width: 10px;

        &:nth-child(1) {
          width: 8px;
          left: -35px;
        }

        &:nth-child(2) {
          left: -25px;
        }

        &:nth-child(3) {
          width: 8px;
          left: -13px;
        }
      }
    }

    &.line-revenue-net {
      .line-width {
        background-color: #666;
        width: 4px;

        &:nth-child(1) {
          width: 5px;
          left: -35px;
        }

        &:nth-child(2) {
          left: -26px;
        }

        &:nth-child(3) {
          left: -18px;
        }

        &:nth-child(4) {
          width: 5px;
          left: -10px;
        }
      }
    }

    &.line-total-cost {
      .line-width {
        background-color: #666;
        width: 1px;

        &:nth-child(1) {
          left: -35px;
        }

        &:nth-child(2) {
          left: -33px;
        }

        &:nth-child(3) {
          left: -31px;
        }

        &:nth-child(4) {
          left: -29px;
        }

        &:nth-child(5) {
          left: -27px;
        }

        &:nth-child(6) {
          left: -25px;
        }

        &:nth-child(7) {
          left: -23px;
        }

        &:nth-child(8) {
          left: -21px;
        }

        &:nth-child(9) {
          left: -19px;
        }

        &:nth-child(10) {
          left: -17px;
        }

        &:nth-child(11) {
          left: -15px;
        }

        &:nth-child(12) {
          left: -13px;
        }

        &:nth-child(13) {
          left: -11px;
        }

        &:nth-child(14) {
          left: -9px;
        }

        &:nth-child(15) {
          left: -7px;
        }
      }
    }

    &.line-gross-profit {
      .line-width {
        background-color: #666;
        width: 4px;

        &:nth-child(1) {
          left: -35px;
        }

        &:nth-child(2) {
          left: -30px;
        }

        &:nth-child(3) {
          left: -25px;
        }

        &:nth-child(4) {
          left: -20px;
        }

        &:nth-child(5) {
          left: -15px;
        }

        &:nth-child(6) {
          left: -10px;
        }
      }
    }
  }
}
</style>
